import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { IComponentDataItem } from "@amcharts/amcharts5/.internal/core/render/Component";
import { Tooltip } from "react-bootstrap";

interface BarChartProps {
  data: { country: string; value: number; 2023: number; 2024: number }[];
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  useEffect(() => {
    const root = am5.Root.new("chartdiv");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Set the background color of the root container to black
    root.container.setAll({
      background: am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 1,
      }),
    });

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        pinchZoomX: false,
      })
    );

    if (root._logo) {
      root._logo.dispose();
    }


    // Set the background color of the chart plot container to black
    chart.plotContainer.setAll({
      background: am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        fillOpacity: 1,
      }),
    });

    // Add cursor
    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    // Create axes
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      rotation: 0, // Rotate labels to -45 degrees
      centerY: am5.p100,
      centerX: am5.p50,
      fill: am5.color(0xffffff),
      fontSize: 15, // Font size of the label
      maxWidth: 80, // Maximum width of the label before wrapping
      oversizedBehavior: "wrap",
      textAlign: "center",
    });

    xRenderer.grid.template.setAll({
      location: 1,
      visible: false,
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "country",
        renderer: xRenderer,
        // tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 1,
    });

    yRenderer.grid.template.setAll({
      visible: true,
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer,
        max: 30,
        min: -35,
      })
    );

    yAxis.children.unshift(
      am5.Label.new(root, {
        text: "vs \n Q4 2023",
        textAlign: "center",
        y: am5.percent(36),
        rotation: 0,
        fontWeight: "bold",
        fill: am5.color(0xffffff),
      })
    );

    const yAxisLabel = am5xy.AxisLabel.new(root, {
      text: "vs\nQ4 2023", // Multiline title with line break (\n)
      centerY: am5.p50, // Position the title vertically in the middle
      fill: am5.color(0xffffff),
      textAlign: "center", // Align the text in the middle
      fontSize: 14,
    });

    // Create series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "country",
        tooltip:am5.Tooltip.new(root, {
          labelText: "2023:  {2023}\n2024:  {2024}",
          pointerOrientation: "up",
        }),
        clustered: false,
      })
    );
    

   
    

    series.columns.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      fillOpacity: 1,
      strokeOpacity: 0,
      strokeWidth: 0,
      maxWidth: 20,
      marginTop: 0,
    });

    // Define gradients
    const positiveGradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(0xf5e003), offset: 0 },
        { color: am5.color(0x000000), offset: 1 },
      ],
    });

    const negativeGradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color(0x000000), offset: 0 },
        { color: am5.color(0x0e7fbe), offset: 1 },
      ],
    });

    // Color columns based on their value with gradient
    series.columns.template.adapters.add("fillGradient", (fill, target) => {
      const dataItem = target?.dataItem as am5.DataItem<
        IComponentDataItem & { valueY: number }
      >; // Adjust type as per your data structure
      return (dataItem?.get("valueY") ?? 0) < 0
        ? negativeGradient
        : (positiveGradient as any);
    });

    // Add labels to the top of columns for positive values and bottom for negative values
    series.bullets.push((root, series, dataItem) => {
      const value = dataItem.get("valueY");
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 1,
        sprite: am5.Label.new(root, {
          fontSize: 18,
          text: "{valueY}%",
          fill: am5.color(0xffffff),
          centerY: value && value < 0 ? am5.p0 : am5.p100,
          centerX: am5.p50,
          populateText: true,
          dy: value && value < 0 ? -5 : 5, // Adjust position for better visibility
        }),
      });
    });

    const myTheme = am5.Theme.new(root);

    myTheme.rule("Grid", ["base"]).setAll({
      stroke: am5.color(0xffffff), // Set color of base line to white
      visible: true, // Ensure base line is visible
      strokeOpacity: 1,
      opacity: 1,
    });

    root.setThemes([myTheme]);

    // Set data
    series.data.setAll(data);
    xAxis.data.setAll(data);

    // Animate appearance
    series.appear(1000);
    chart.appear(1000, 100);

    // Clean up on unmount
    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div
      id="chartdiv"
      style={{ width: "100%", height: "100%", minHeight: "500px" }}
    ></div>
  );
};

export default BarChart;
