import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import VBarChart from "../../../graphs/VBarChart";

interface ExcelData {
  Country: string;
  Period: string | number; // Period can now be a string or a number (Excel serial date)
  CPI: number; // Renamed "Consumer Price Index" to "CPI"
}

interface Props {
  updateEvent: (data: any) => void;
  updateEventData: (data: any) => void;
}

// Helper function to convert Excel serial date to JavaScript Date object
const excelDateToJSDate = (serial: number) => {
  const date = new Date((serial - 25569) * 86400 * 1000); // Convert Excel serial date to JS date
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`; // Format as MM/DD/YYYY
  return formattedDate; // Return date without time
};

const PercentageChangeCPI: React.FC<Props> = ({
  updateEvent,
  updateEventData,
}) => {
  const [percentageData, setPercentageData] = useState<
    { country: string; value: number }[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the file from the public directory
        const response = await fetch(
          `${process.env.PUBLIC_URL}/excel-data/Q4 Sec3 Data.xlsx`
        );

        // Check if the fetch was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Read the workbook from the array buffer
        const workbook = read(arrayBuffer, { type: "array" });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // Convert the worksheet to JSON
        const jsonData = utils.sheet_to_json<any>(worksheet);

        // Filter out rows without valid data and rename "Consumer Price Index" to "CPI"
        const filteredData = jsonData
        .filter(
          (row: any) =>
            row.Country &&
            row.Values === "Sum of Consumer Price Index_monthly" &&
            (row["2024"] !== undefined || row["2025"] !== undefined)
        )
        .map((row) => ({
          Country: row.Country,
          CPI2023: row["2024"] || 0, // Use 0 if the value is missing
          CPI2024: row["2025"] || 0, // Use 0 if the value is missing
        }));

      const groupedData: { [key: string]: any[] } = {};
      filteredData.forEach((row: any) => {
        if (row.Country === "Argentina") return; // Exclude Argentina
        if (!groupedData[row.Country]) {
          groupedData[row.Country] = [];
        }
        groupedData[row.Country].push(row);
      });

      const percentageData: { country: string; value: number }[] = [];

      Object.keys(groupedData).forEach((country) => {
        const countryData = groupedData[country][0]; // Assuming only one row per country

        const { CPI2023, CPI2024 } = countryData;

        // Calculate percentage change
        const percentageChange = CPI2023 !== 0 ? ((CPI2024 - CPI2023) / CPI2023) * 100 : 0;

        percentageData.push({
          country: country,
          value: isNaN(percentageChange) ? 0 : Number(percentageChange.toFixed(1)),
        });
      });

console.log(percentageData);

      
      console.log("percentageData",percentageData);
      
        updateEvent(percentageData);
        updateEventData({ CPI: percentageData });
        setPercentageData(percentageData);
        console.log("Percentage Change CPI Data:", percentageData);
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {percentageData.length && (
        <VBarChart
          data={percentageData}
          id="percentageChangeCPI"
          is_xAxis={false}
        />
      )}
    </div>
  );
};

export default PercentageChangeCPI;
