import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Graph2 from "../../assets/images/graph2.png";
import Bulb from "../../assets/images/bulb.png";
import SelectBG from "../../assets/images/selectBack.svg";
import PerformanceCharts from "./PerformaceCharts";
import { read, utils } from "xlsx";
import KpiCards from "./KpiCards";
import WinsCharts from "./WinsChart";
import FyChart from "./FyChart";
import BeerYTD from "./Sec3BarGraph/BeerYTD";
import CPI from "./Sec3BarGraph/CPI";
import DI from "./Sec3BarGraph/DI";
import IPI from "./Sec3BarGraph/IPI";
import GDP from "./Sec3BarGraph/GDP";
import { array } from "@amcharts/amcharts5";
import MobileView from "./Sec3BarGraph/MobileView";
interface DataRow {
  Country: string;
  LEVersion: string;
  Account: string;
  ACLEGACQ224: string;
  LYAmendedQ224: string;
  BUAmendedQ224: string;
}

interface KpiValues {
  account: string;
  ACLEGACQ224: number;
  lyComparison: number;
  buComparison: number;
}

export default function HomeTab() {
  const [kpiValues, setKpiValues] = useState<any>({});
  const [key, setKey] = useState("Performance");
  const [countries, setCountries] = useState<any>([]);
  const [dataMobileView, setDataMobileView] = useState<any>({});
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    // Add event listener for resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log("dataMobileView", dataMobileView);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the file from the public directory
        const response = await fetch(
          `${process.env.PUBLIC_URL}/excel-data/EXP01 Pulse Report Export.xlsx`
        );

        // Check if the fetch was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Ensure the file is read as binary
        const workbook = read(arrayBuffer, { type: "array" });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // Generate array of data from the worksheet
        const data: any[] = utils.sheet_to_json<any>(worksheet);
        const renamedData = data.map((row) => ({
          ...row,
          Account: row["Accounts IM Simplified"], // Rename column
          Country: row["GAC Reporting Country"], // Rename column
          LEVersion: row["SS: CY Versions"], // Rename column
          ACLEGACQ424: row["AC/LEQ4"],

          // Add any other necessary renames here
        }));

        // Define the condition
        const condition = {
          Country: "Total ABI",
          LEVersion: "LE 12+0 2024",
        };

        const accounts = ["Volume", "Net Revenue", "MACO"];
        // const kpiValuesTemp: KpiValues[] = [];
        const kpiValuesTemp: any = {};

        accounts.forEach((account) => {
          const matchingRow = renamedData.find(
            (row) =>
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion &&
              row.Account === account
          );

          if (matchingRow) {
            const ACLEGACQ224 = parseFloat(matchingRow.ACLEGACQ424) / 1000;
            const lyAmended = parseFloat(matchingRow.LYAMQ4) / 1000;
            const buAmended = parseFloat(matchingRow.BUAMQ4) / 1000;

            if (!isNaN(ACLEGACQ224) && !isNaN(lyAmended) && !isNaN(buAmended)) {
              const lyComparison = (ACLEGACQ224 / lyAmended - 1) * 100;
              const buComparison = (ACLEGACQ224 / buAmended - 1) * 100;

              kpiValuesTemp[account] = {
                ACLEGACQ224: ACLEGACQ224.toFixed(2),
                lyComparison: lyComparison.toFixed(1),
                buComparison: buComparison.toFixed(1),
              };
            }
          }
        });
        setKpiValues(kpiValuesTemp);
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    };

    fetchData();
  }, []);

  async function updateData(e: any) {
    setDataMobileView((prevState: any) => ({
      ...prevState,
      ...e,
    }));
  }

  return (
    <>
      <KpiCards kpiValues={kpiValues} />

      {/* <div className="TabFilters">
        <select name="" id="">
          <option value="Quarter">vs LY</option>
          <option value="Quarter">vs LY</option>
          <option value="Quarter">vs LY</option>
          <option value="Quarter">vs LY</option>
        </select>
        <select name="" id="">
          <option value="Quarter">Quarter 1</option>
          <option value="Quarter">Quarter 2</option>
          <option value="Quarter">Quarter 3</option>
          <option value="Quarter">Quarter 4</option>
        </select>
      </div> */}
      <div className="MobileTabDropdown">
        <label>Please Select the Graph.</label>
        <div className="selectWrap">
          <img src={SelectBG} />
          <select
            name=""
            id=""
            value={key}
            onChange={(k) => setKey(k.target.value || "Performance")}
          >
            <option value="Performance">Performance</option>
            <option value="WinsMisses">Wins & Misses</option>
            <option value="Trends">Trends</option>
            <option value="FY_Projection">FY Projection</option>
          </select>
        </div>
      </div>
      <Tabs
        defaultActiveKey="Performance"
        id="home-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k || "Performance")}
      >
        <Tab eventKey="Performance" title="Performance">
          <div className="tabContent">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="chartBG">
                  <PerformanceCharts />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="SmallTitle">
                  <div>
                    <img src={Bulb} />
                  </div>
                  <div>
                    <h2>Insights</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4 mb-5">
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <p className="m-0">
                    <span className="yellow f-20">MAZ </span>
                  </p>
                  <p>
                  After a weak Q3, Mexico Q4 performance improved despite Hurricane Milton in Sep. This was due to an investment of $10Mn and soft comp from hurricane Otis in 2023. LA Nina (lower temperature and higher precipitation) and possible challenges influenced by future US policy can have adverse effects on Mexico’s economy in the near term causing potential impact to volumes. Peru continued recovery in Q4 as well, from the negative impact in H1 due to re-enforcement of tax regime.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <p className="m-0">
                    <span className="yellow f-20">EUR </span>
                  </p>
                  <p>
                  With Household disposable income seeing some improvement in recent times, low consumer confidence continues to contract the demands. With high inflation still in mind, Households appear reluctant to consume their extra income.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <p className="m-0">
                    <span className="yellow f-20">China</span>
                  </p>
                  <p>
                  China’s decline in ABI volumes continued into Q4 with additional headwinds of unusual cold spell which broke mid-October records in Northern China. Though China’s economy grew around 5% in the fourth quarter of 2024 through a series of measures including interest rate cuts, export price cuts and fiscal funding, Q4 ABI volumes contracted further by 19% in comparison to last year. Investment & Government consumption is expected to improve in 2025.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="WinsMisses" title="Wins & Misses">
          <div className="tabContent">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="chartBG">
                  <WinsCharts />
                </div>
              </div>
            </div>
            {/* <div className="row mt-5">
              <div className="col-md-12">
                <div className="SmallTitle">
                  <div>
                    <img src={Bulb} />
                  </div>
                  <div>
                    <h2>Insights</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam vel
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4 mb-5">
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <i className="fa-solid fa-arrow-right"></i>
                  <p className="m-0">
                    <b>US Beer</b>
                  </p>
                  <p>
                    <span className="yellow">Lower PINC</span> expected in YTG
                    (BU inputs) leading to higher YTG volumes in LE (8+4)
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <i className="fa-solid fa-arrow-right"></i>
                  <p className="m-0">
                    <b>China</b>
                  </p>
                  <p>
                    Higher impact of night life ban observed across regions.{" "}
                    <span className="yellow">
                      Flood impact in Fujian led to lower volumes
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <i className="fa-solid fa-arrow-right"></i>
                  <p className="m-0">
                    <b>Argentina Beer</b>
                  </p>
                  <p>
                    Declining economic conditions in China impacting Korean
                    exports.{" "}
                    <span className="yellow">
                      Korean Airlines launched its own craft Lager in July
                      leading to higher competition.
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </Tab>
        <Tab eventKey="Trends" title="Trends">
          <div className="tabContent">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="chartBG">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="chartTitleData">
                        <h2 className="ChartTitle ChartTitle-sec3">
                          Macro indicator trends projected for 2025 FY
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="volumeChart"
                        style={{ display: isMobile ? "none" : "block" }}
                      >
                        <div className="subVolumeChart">
                          <div
                            className="outer-background"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              background: "#161616",
                              borderRadius: "13.092px",
                              padding: "20px",
                              gap: "10px",
                            }}
                          >
                            <div
                              className="label-names"
                              style={{ width: "10%" }}
                            >
                              <div
                                className="country-list"
                                style={{
                                  paddingTop: "79px",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "26px",
                                  color: "#fff",
                                  fontSize: "15px",
                                }}
                              >
                                {countries.length ? (
                                  [...countries]
                                    .reverse()
                                    .map((country: any) => (
                                      <div className="country-item">
                                        {country.country}
                                      </div>
                                    ))
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div
                              className="chart-container"
                              style={{
                                display: "flex",
                                width: "90%",
                                gap: "10px",
                              }}
                            >
                              <div
                                className="inner-background"
                                style={{
                                  width: "50%",
                                  background: "#202020",
                                  boxShadow:
                                    " 7.855px 5.237px 18.917px 0px rgba(0, 0, 0, 0.25)",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  borderRadius: "10px",
                                  padding: "10px",
                                }}
                              >
                                <div style={{ display: "flex", gap: "5px" }}>
                                  <div
                                    className="div-block"
                                    style={{ width: "62%" }}
                                  >
                                    <p className="ta-c">Consumer Price Index</p>
                                    <CPI
                                      updateEvent={setCountries}
                                      updateEventData={updateData}
                                    />
                                  </div>
                                  <div
                                    className="div-block"
                                    style={{ width: "62%" }}
                                  >
                                    <p className="ta-c">Disposable Income</p>
                                    <DI updateEventData={updateData} />
                                  </div>
                                </div>
                                <div className="text-block-2">
                                  <p>Demand side indicators</p>
                                </div>
                              </div>
                              <div
                                className="inner-background"
                                style={{
                                  width: "50%",
                                  background: "#202020",
                                  boxShadow:
                                    " 7.855px 5.237px 18.917px 0px rgba(0, 0, 0, 0.25)",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  borderRadius: "10px",
                                  padding: "10px",
                                }}
                              >
                                <div style={{ display: "flex", gap: "5px" }}>
                                  <div
                                    className="div-block"
                                    style={{ width: "62%" }}
                                  >
                                    <p className="ta-c">
                                      Industrial Production Index
                                    </p>
                                    <IPI updateEventData={updateData}></IPI>
                                  </div>
                                  <div
                                    className="div-block"
                                    style={{ width: "62%" }}
                                  >
                                    <p className="ta-c">GDP</p>
                                    <GDP updateEventData={updateData}></GDP>
                                  </div>
                                </div>
                                <div className="text-block-2">
                                  <p>Supply side indicators</p>
                                </div>
                              </div>

                              {/* <div
                            className="inner-background beerIndus"
                            style={{
                              width: "24%",
                              background: "transparent",
                              boxShadow:
                                " 7.855px 5.237px 18.917px 0px rgba(0, 0, 0, 0.25)",
                              // height: "46%",
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <div
                              className="div-block"
                              style={{ width: "100%" }}
                            >
                              <p>Beer Industry YTD</p>
                              <BeerYTD></BeerYTD>
                            </div>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: isMobile ? "block" : "none" }}>
                        {dataMobileView.CPI &&
                        dataMobileView.CPI.length > 0 &&
                        dataMobileView.DI &&
                        dataMobileView.DI.length > 0 &&
                        dataMobileView.IPI &&
                        dataMobileView.IPI.length > 0 &&
                        dataMobileView.GDP &&
                        dataMobileView.GDP.length > 0 ? (
                          <MobileView inputData={dataMobileView}></MobileView>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="source-text">Source: IHS Markit</div>
            {/* <div className="row mt-5">
              <div className="col-md-12">
                <div className="SmallTitle">
                  <div>
                    <img src={Bulb} />
                  </div>
                  <div>
                    <h2>Insights</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam vel
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4 mb-5">
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <i className="fa-solid fa-arrow-right"></i>
                  <p className="m-0">
                    <b>US Beer</b>
                  </p>
                  <p>
                    <span className="yellow">Lower PINC</span> expected in YTG
                    (BU inputs) leading to higher YTG volumes in LE (8+4)
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <i className="fa-solid fa-arrow-right"></i>
                  <p className="m-0">
                    <b>China</b>
                  </p>
                  <p>
                    Higher impact of night life ban observed across regions.{" "}
                    <span className="yellow">
                      Flood impact in Fujian led to lower volumes
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <i className="fa-solid fa-arrow-right"></i>
                  <p className="m-0">
                    <b>Argentina Beer</b>
                  </p>
                  <p>
                    Declining economic conditions in China impacting Korean
                    exports.{" "}
                    <span className="yellow">
                      Korean Airlines launched its own craft Lager in July
                      leading to higher competition.
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </Tab>
        <Tab eventKey="FY_Projection" title="FY Projection">
          <div className="tabContent">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="chartBG">
                  <FyChart />
                </div>
              </div>
            </div>

            {/* <div className="row mt-4 mb-5">
              <div className="col-md-4">
                <div className="greyCard insightCard">
                  <p className="m-0">
                    <b>Lorem Ipsum</b>
                  </p>
                  <p>
                    <span className="yellow">Lorem Ipsum</span> Lorem Ipsum
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="greyCard insightCard">

                  <p className="m-0">
                    <b> Lorem Ipsum</b>
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem
                    ipsum dolor sit amet, consectetur adipiscing elit
                    <span className="yellow">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="greyCard insightCard">

                  <p className="m-0">
                    <b> Lorem Ipsum</b>
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
                    <span className="yellow">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </Tab>
      </Tabs>
    </>
  );
}
