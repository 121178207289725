import React from "react";
import "./footer.css";
import abilogo from "../../assets/images/abiLogo.png";
import User1 from "../../assets/images/user1.png";

export default function footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img className="mb-2 mt-2" src={abilogo} />
            <hr />
            <p>© 2025 Anheuser-Busch InBev All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
