import BarChart from "../../graphs/BarChart";
import React, { useEffect, useState, useRef } from "react";
import { read, utils } from "xlsx";
import WaterfallChartFy from "../../graphs/WaterfallChartFy";

interface President {
  Country: string;
  LEVersion: string;
  BU: number;
  Account: string;
  LY: number;
  GAC: string; // Treating GAC as a string to handle mixed content
  FYAcleGACAmmended: string;
  FYLYAmmended: number;
  FYBUAmmended: number;
}

interface Condition {
  Account: string;
  Country: string;
  LEVersion: string;
}

interface Result {
  Account: string;
  Country: string;
  LEVersion: string;
  actualValue: number;
  forecastValue: number;
  difference: string;
}

interface BoxResult {
  Country: string;
  BoxValue: string;
}

// interface WaterfallChartProps {
//   data: {
//     columnConfig?: { fill: any } | undefined;
//     category: string;
//     value: number;
//     open: number;
//     stepValue?: number;
//     displayValue: number;
//     BoxValue: number;
//   }[];
// }

// interface President_box {
//   Country: string;
//   LEVersion: string;
//   BU: number;
//   Account: string;
//   LY: number;
//   GAC: string; // Treating GAC as a string to handle mixed content
//   ACLEGACQ224: string;
//   LYAmendedQ124: number;
//   BUAmendedQ224: number;
// }

// interface Condition_box {
//   Account: string;
//   Country: string;
//   LEVersion: string;
// }

// interface Result_box {
//   Account: string;
//   Country: string;
//   LEVersion: string;
//   actualValue: number;
//   forecastValue: number;
//   difference: string;
// }

const FyChart = () => {
  const stepValuesRef = useRef<HTMLDivElement>(null);
  //const [finalResults, setFinalResult] = useState<WaterfallChartProps>();
  const [pres, setPres] = useState<President[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [Boxresults, setBoxResults] = useState<BoxResult[]>([]);
  const [BoxformattedData, setBoxFormattedData] = useState<any[]>([]);
  const [formattedData, setFormattedData] = useState<any[]>([]);

  const [totalABI, setTotalABI] = useState<{
    actualValue: number;
    forecastValue: number;
  } | null>(null);
  const [sixCountriesActualValue, setSixCountriesActualValue] =
    useState<number>(0);
  const [sixCountriesForecastValue, setSixCountriesForecastValue] =
    useState<number>(0);
  const [sixCountriesDifference, setSixCountriesDifference] =
    useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the file from the public directory
        const response = await fetch(
          `${process.env.PUBLIC_URL}/excel-data/Pulse LE 0+12 2025.xlsx`
        );

        // Check if the fetch was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();

        // Ensure the file is read as binary
        const workbook = read(arrayBuffer, { type: "array" });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // Generate array of presidents from the first worksheet
        const data: any[] = utils.sheet_to_json<any>(worksheet);

        const renamedData = data.map((row) => ({
          ...row,
          Account: row["Accounts IM Simplified"], // Rename column
          Country: row["GAC Reporting Country"],  // Rename column
          LEVersion: row["SS: CY Versions"],      // Rename column
          ACLEGACQ424: row["AC/LEQ4"],
          FYAcleGACAmmended: row["AC/LEFY"]


          // Add any other necessary renames here
        }));

        // Define conditions for ActualValue and ForecastValue
        const conditions: Condition[] = [
          { Account: "Volume", Country: "Total ABI", LEVersion: "LE 0+12 2025" },
          { Account: "Volume", Country: "US Beer", LEVersion: "LE 0+12 2025" },
          { Account: "Volume", Country: "Mexico", LEVersion: "LE 0+12 2025" },
          {
            Account: "Volume",
            Country: "Brazil Beer",
            LEVersion: "LE 0+12 2025",
          },
          { Account: "Volume", Country: "China", LEVersion: "LE 0+12 2025" },
          {
            Account: "Volume",
            Country: "Colombia Beer",
            LEVersion: "LE 0+12 2025",
          },
          { Account: "Volume", Country: "EUR", LEVersion: "LE 0+12 2025" },
          { Account: "Volume", Country: "Peru Beer", LEVersion: "LE 0+12 2025" },
          {
            Account: "Volume",
            Country: "South-Africa",
            LEVersion: "LE 0+12 2025",
          },
          {
            Account: "Volume",
            Country: "Dominican Republic Beer",
            LEVersion: "LE 0+12 2025",
          },
          {
            Account: "Volume",
            Country: "Argentina Beer",
            LEVersion: "LE 0+12 2025",
          },
          // Add more conditions as needed
        ];

        // Define conditions for the six specified countries
        const sixCountriesConditions: Condition[] = [
          { Account: "Volume", Country: "Canada", LEVersion: "LE 0+12 2025" },
          {
            Account: "Volume",
            Country: "Ecuador Beer",
            LEVersion: "LE 0+12 2025",
          },
          {
            Account: "Volume",
            Country: "South Korea",
            LEVersion: "LE 0+12 2025",
          },
          {
            Account: "Volume",
            Country: "Honduras Beer",
            LEVersion: "LE 0+12 2025",
          },
          {
            Account: "Volume",
            Country: "El Salvador Beer",
            LEVersion: "LE 0+12 2025",
          },
          { Account: "Volume", Country: "Nigeria", LEVersion: "LE 0+12 2025" },
        ];

        const tempResults: Result[] = [];
        const BoxtempResults: BoxResult[] = [];

        let totalABIActualValue = 0;
        let totalABIForecastValue = 0;
        let sixCountriesActualValueTemp = 0;
        let sixCountriesForecastValueTemp = 0;
        let RestCountryActualValue = 0;
        let RestCountryForecastValue = 0;
        let CountryActualValue = 0;
        let CountryForecastValue = 0;

        let BoxCountryActualValue = 0;
        let BoxCountryForecastValue = 0;
        let BoxsixCountriesActualValueTemp = 0;
        let BoxsixCountriesForecastValueTemp = 0;

        conditions.forEach((condition) => {
          const row = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (row) {
            const actualValue = parseFloat(row.FYAcleGACAmmended);
            const forecastValue = row.BUAMFY;

            if (!isNaN(actualValue) && !isNaN(forecastValue)) {
              const BoxValue = ((actualValue - forecastValue) / 1000).toFixed(
                1
              );
              BoxtempResults.push({
                Country: condition.Country,
                BoxValue: `${BoxValue}`,
              });

              if (
                condition.Country === "Total ABI" &&
                condition.Account === "Volume" &&
                condition.LEVersion === "LE 0+12 2025"
              ) {
                totalABIActualValue = actualValue;
                totalABIForecastValue = forecastValue;
              } else {
                BoxCountryActualValue += actualValue;
                BoxCountryForecastValue += forecastValue;
              }
            }
          }
        });
        const BUAmmendedObject = {
          Country: "FYBUAmmended",
          BoxValue: `${(totalABIForecastValue / 1000).toFixed(1)}`,
        };
        const BoxFYGACObject = {
          Country: "Global LE (6+6)",
          BoxValue: `${(totalABIActualValue / 1000).toFixed(1)}`,
        };

        sixCountriesConditions.forEach((condition) => {
          const row = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (row) {
            const actualValue = parseFloat(row.FYAcleGACAmmended);
            const forecastValue = row.BUAMFY;

            if (!isNaN(actualValue)) {
              BoxsixCountriesActualValueTemp += actualValue;
            }
            if (!isNaN(forecastValue)) {
              BoxsixCountriesForecastValueTemp += forecastValue;
            }
          }
        });

        const BoxsixCountriesDifference = (
          (BoxsixCountriesActualValueTemp - BoxsixCountriesForecastValueTemp) /
          1000
        ).toFixed(1);
        BoxtempResults.push({
          Country: "Next5",
          BoxValue: `${BoxsixCountriesDifference}`,
        });

        RestCountryActualValue =
          BoxCountryActualValue + BoxsixCountriesActualValueTemp;
        RestCountryForecastValue =
          BoxCountryForecastValue + BoxsixCountriesForecastValueTemp;
        if (totalABIForecastValue - RestCountryForecastValue !== 0) {
          const BoxcalculatedPercent =
            totalABIActualValue -
            RestCountryActualValue -
            (totalABIForecastValue - RestCountryForecastValue);

          BoxtempResults.push({
            Country: "Tail Market",
            BoxValue: `${(BoxcalculatedPercent / 1000).toFixed(1)}`,
          });
        }

        setBoxResults(BoxtempResults);
        const BoxFormattedResults = [
          BUAmmendedObject,
          ...BoxtempResults,
          BoxFYGACObject,
        ];
        const countriesToRemove = ["Total ABI"];
        const BoxfilteredArray = BoxFormattedResults.filter(
          (item) => !countriesToRemove.includes(item.Country)
        );

        setBoxFormattedData(BoxfilteredArray);
        

        //Calculation for waterfall chart
        conditions.forEach((condition) => {
          const row = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (row) {
            const actualValue = parseFloat(row.FYAcleGACAmmended);
            const forecastValue = row.LYAMFY;

            if (!isNaN(actualValue) && !isNaN(forecastValue)) {
              const difference = ((actualValue - forecastValue) / 1000).toFixed(
                1
              ); // Calculate difference
              tempResults.push({
                Account: condition.Account,
                Country: condition.Country,
                LEVersion: condition.LEVersion,
                actualValue,
                forecastValue,
                difference: `${difference}`, // Format difference
              });

              // Store the ActualValue and ForecastValue for "Total ABI"
              if (
                condition.Country === "Total ABI" &&
                condition.Account === "Volume" &&
                condition.LEVersion === "LE 0+12 2025"
              ) {
                totalABIActualValue = actualValue;
                totalABIForecastValue = forecastValue;
              } else {
                CountryActualValue += actualValue;
                CountryForecastValue += forecastValue;
              }
            }
          }
        });
        console.log("totalABI",totalABIActualValue)
        console.log("totalABIForecastValue",totalABIForecastValue)

        const LYAmmendedObject = {
          Country: "LY",
          value: (totalABIForecastValue / 1000).toFixed(1),
          open: 0, // Adjust open value as needed
          stepValue: (totalABIForecastValue / 1000).toFixed(1),
          columnConfig: {}, // Add empty columnConfig as placeholder
          displayValue: (totalABIForecastValue / 1000).toFixed(1),
        };

        // Calculate actual and forecast values for six specified countries
        sixCountriesConditions.forEach((condition) => {
          const matchingRow = renamedData.find(
            (row) =>
              row.Account === condition.Account &&
              row.Country === condition.Country &&
              row.LEVersion === condition.LEVersion
          );

          if (matchingRow) {
            const countryActualValue = parseFloat(
              matchingRow.FYAcleGACAmmended
            );
            const countryForecastValue = matchingRow.LYAMFY;

            if (!isNaN(countryActualValue)) {
              sixCountriesActualValueTemp += countryActualValue; //74288
            }
            if (!isNaN(countryForecastValue)) {
              sixCountriesForecastValueTemp += countryForecastValue; //72566.755
            }
          }
        });

        // Calculate the difference between the sum of actual and forecast values for six countries
        const sixCountriesDifference = (
          (sixCountriesActualValueTemp - sixCountriesForecastValueTemp) /
          1000
        ).toFixed(1);
        tempResults.push({
          Account: "Volume",
          Country: "Next5",
          LEVersion: "LE 0+12 2025",
          actualValue: sixCountriesActualValueTemp,
          forecastValue: sixCountriesForecastValueTemp,
          difference: `${sixCountriesDifference}`,
        });
        RestCountryActualValue =
          CountryActualValue + sixCountriesActualValueTemp;
        RestCountryForecastValue =
          CountryForecastValue + sixCountriesForecastValueTemp;
        if (totalABIForecastValue - RestCountryForecastValue !== 0) {
          const calculatedPercent =
            totalABIActualValue -
            RestCountryActualValue -
            (totalABIForecastValue - RestCountryForecastValue);

          tempResults.push({
            Account: "Volume",
            Country: "Tail Market",
            LEVersion: "LE 0+12 2025",
            actualValue: totalABIActualValue - RestCountryActualValue,
            forecastValue: totalABIForecastValue - RestCountryForecastValue,
            difference: `${(calculatedPercent / 1000).toFixed(1)}`, // Format difference
          });
        }

        setResults(tempResults);

        setTotalABI({
          actualValue: totalABIActualValue,
          forecastValue: totalABIForecastValue,
        });
        setSixCountriesActualValue(sixCountriesActualValueTemp);
        setSixCountriesForecastValue(sixCountriesForecastValueTemp);
        setSixCountriesDifference(sixCountriesDifference);

        var openValue = totalABIForecastValue / 1000;
        const filteredArray = tempResults.filter(
          (item) => item.Country !== "Total ABI"
        );
        const formattedResults = filteredArray.map((result, index) => {
          const oldValue = openValue;
          openValue = openValue + parseFloat(result.difference);
          return {
            Country: result.Country.replace("-", " "),
            value: openValue,
            open: oldValue,
            stepValue: openValue,
            columnConfig: {},
            displayValue: result.difference,
          };
        });

        const FYGACObject = {
          Country: "Global LE (0+12)",
          value: (totalABIActualValue / 1000).toFixed(1),
          open: 0, // Use stepValue of the previous item as open value
          stepValue: (totalABIActualValue / 1000).toFixed(1),
          columnConfig: {}, // Placeholder for column configuration
          displayValue: (totalABIActualValue / 1000).toFixed(1),
          BoxValue: 0, // Adjust BoxValue as needed
        };

        setFormattedData(formattedResults);
        const updatedFormattedResults = [
          LYAmmendedObject, // Insert LYAmmendedObject at index 0 (first index)
          ...formattedResults, // Spread remaining elements from formattedResults
          FYGACObject, // Insert FYGACObject at the end (last index)
        ];

        // Now updatedFormattedData has LYAmmendedObject at index 1
        setFormattedData(updatedFormattedResults);
       
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (stepValuesRef.current) {
      stepValuesRef.current.innerHTML = ""; // Clear previous content

      BoxformattedData.forEach((item, index) => {
        const mainBox = document.createElement("div");
        mainBox.className = "mainBox";
        const labelBox = document.createElement("div");
        labelBox.style.color = "#fff";
        if (index === 0) {
          labelBox.innerText = "Budget";
        } else if (index === BoxformattedData.length - 1) {
          labelBox.innerText = "Global LE (0+12)";
          labelBox.style.width = "80px";
        } else if (index === BoxformattedData.length - 2) {
          labelBox.innerText = "Tail Market";
        } else if (index === BoxformattedData.length - 3) {
          labelBox.innerText = "Next5";
        }
        const stepValueBox = document.createElement("div");
        stepValueBox.style.display = "inline-block";
        stepValueBox.style.width = "70px";
        stepValueBox.style.height = "30px";
        stepValueBox.style.margin = "5px";
        stepValueBox.style.color = "#fff";
        stepValueBox.style.textAlign = "center";
        stepValueBox.style.lineHeight = "30px";

        // Set background color based on item type
        if (index === 0 || index === BoxformattedData.length - 1) {
          stepValueBox.style.backgroundColor = "#FFFFFF"; // White for first and last
          stepValueBox.style.color = "#000000"; // Black text for contrast
        } else if (Number(item.BoxValue) <= 0) {
          stepValueBox.style.backgroundColor = "#0B70A9"; // Blue for decreasing step
        } else if (Number(item.BoxValue) > 0) {
          stepValueBox.style.backgroundColor = "#E9D40B"; // Yellow for increasing step
          stepValueBox.style.color = "#000000"; // Black text for contrast
        }

        // Use displayValue for the first and last items, stepValue for others
        stepValueBox.innerText = item.BoxValue;
        mainBox.appendChild(stepValueBox);
        mainBox.appendChild(labelBox);
        if (stepValuesRef.current) {
          stepValuesRef.current.appendChild(mainBox);
        }
      });
    }
  }, [BoxformattedData, formattedData]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="chartTitleData">
          <h2 className="ChartTitle">FY Projection 2025</h2>
          <p>
          2025 is projected to grow at {" "}
            <span className="yellow">
              {formattedData.length > 0
                ? (
                    (formattedData[formattedData.length - 1]["value"] /
                      formattedData[0]["value"] -
                      1) *
                    100
                  ).toFixed(1)
                : ""}
              {"%"}
            </span>{" "}
            {"("}
            <span className="yellow">
              {formattedData.length > 0 &&
              formattedData[formattedData.length - 1]["value"] -
                formattedData[0]["value"] >
                0
                ? "+"
                : ""}
              {formattedData.length > 0
                ? (
                    formattedData[formattedData.length - 1]["value"] -
                    formattedData[0]["value"]
                  ).toFixed(1)
                : ""}
              {"Mhl"}
            </span>{" "}
            {") "}
            higher than LY which translates to
            <span className="yellow">
              {" "}
              {BoxformattedData.length > 0
                ? Math.abs(
                    (BoxformattedData[BoxformattedData.length - 1]["BoxValue"] /
                      BoxformattedData[0]["BoxValue"] -
                      1) *
                      100
                  ).toFixed(1)
                : ""}
              {"% "}
            </span>
            {BoxformattedData.length &&
            BoxformattedData[BoxformattedData.length - 1]["BoxValue"] -
              BoxformattedData[0]["BoxValue"] >
              0
              ? "above "
              : "below "}
            Budget. Major markets leading to the decline are China & US Beer.
          </p>
        </div>
      </div>
      <div className="col-md-12">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="BannData text-center py-5">
                <div className="volumeChart">
                  <div className="subVolumeChart">
                    {formattedData && <WaterfallChartFy data={formattedData} />}
                    <div className="footnote-container">
                      <p className="footnote">
                        LY Amended numbers are as on 21-Jan-25 from Anaplan
                      </p>
                    </div>

                    {/* <div className="dis_f_c">
                      <div className="r_90">
                        <p style={{ display: "contents", color: "#fff" }}>Volume (Mhl)</p>
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            position: "relative",
                            marginTop: "30px",
                            paddingRight: "20px",
                          }}
                        >
                          <hr
                            style={{
                              border: "1px solid #fff",
                              position: "relative",
                              top: "10px",
                              backgroundColor: "black",
                              paddingRight: "20px",
                              opacity: "0.4",
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#000000",
                              color: "#ffffff",
                              padding: "0 10px",
                              borderRadius: "8px",
                              border: "1px solid #707070",
                            }}
                          >
                            {BoxformattedData.length > 0
                              ? (
                                  BoxformattedData[BoxformattedData.length - 1][
                                    "BoxValue"
                                  ] - BoxformattedData[0]["BoxValue"]
                                ).toFixed(1)
                              : ""}{" "}
                            Mhl
                            {BoxformattedData.length &&
                            BoxformattedData[BoxformattedData.length - 1][
                              "BoxValue"
                            ] -
                              BoxformattedData[0]["BoxValue"] >
                              0
                              ? " higher"
                              : " lower"}{" "}
                            (
                            {BoxformattedData.length
                              ? (
                                  ((BoxformattedData[
                                    BoxformattedData.length - 1
                                  ]["BoxValue"] -
                                    BoxformattedData[0]["BoxValue"]) /
                                    BoxformattedData[
                                      BoxformattedData.length - 1
                                    ]["BoxValue"]) *
                                  100
                                ).toFixed(1)
                              : ""}
                            %)
                          </span>
                          <div
                            style={{
                              position: "absolute",
                              left: "0%",
                              transform: "translateX(-50%)",
                              color: "#fff",
                              top: "10px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="39"
                              viewBox="0 0 5 39"
                              fill="none"
                            >
                              <path
                                d="M2.50586 38.4637L4.48055 35.0435L0.531166 35.0435L2.50586 38.4637ZM2.16383 0.156738L2.16383 35.3855L2.84788 35.3855L2.84789 0.156738L2.16383 0.156738Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "20px",
                              transform: "translateX(50%)",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="5"
                              height="39"
                              viewBox="0 0 5 39"
                              fill="none"
                            >
                              <path
                                d="M2.50586 38.4637L4.48055 35.0435L0.531166 35.0435L2.50586 38.4637ZM2.16383 0.156738L2.16383 35.3855L2.84788 35.3855L2.84789 0.156738L2.16383 0.156738Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                        <div
                          ref={stepValuesRef}
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexWrap: "wrap",
                            paddingTop: "50px",
                            paddingLeft: "0px",
                            paddingRight: "20px",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            position: "relative",
                            marginTop: "10px",
                          }}
                        >
                          <hr
                            style={{
                              border: "1px solid #707070",
                              position: "relative",
                              top: "10px",
                              backgroundColor: "black",
                              marginBottom: "30px",
                              opacity: "1",
                              marginRight: "355px",
                              marginLeft: "130px",
                            }}
                          />

                          <span
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "41%",
                              transform: "translateX(-50%)",
                              background: "#000000",
                              color: "#ffffff",
                              padding: "0 10px",
                              borderRadius: "8px",
                              border: "1px solid #707070",
                            }}
                          >
                            Top 10 Market
                          </span>
                        </div>
                      </div>
                    </div> */}
         
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FyChart;
